<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import {
  solarPanelsPurchaseConsumerNL,
  solarPanelsPurchaseSolarCollectorsConsumerNL,
  solarPanelsRentConsumerNL,
} from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable(
          'Minder dan 10.000 kWh (consumentenverbruik)',
          'Minder dan 10.000 kWh (consumentenverbruik)',
          'Minder dan 10.000 kWh (consumentenverbruik)',
        ),
        new Selectable(
          'Tussen de 10.000 en 35.000 kWh',
          'Tussen de 10.000 en 35.000 kWh',
          'Tussen de 10.000 en 35.000 kWh',
        ),
        new Selectable(
          'Meer dan 35.000 kWh (Grootverbruik)',
          'Meer dan 35.000 kWh (Grootverbruik)',
          'Meer dan 35.000 kWh (Grootverbruik)',
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 persoon)',
          '2.300 kWh (1 persoon)',
          '2.300 kWh (1 persoon)',
        ),
        new Selectable(
          '3.400 kWh (2 personen)',
          '3.400 kWh (2 personen)',
          '3.400 kWh (2 personen)',
        ),
        new Selectable(
          '4.100 kWh (3 personen)',
          '4.100 kWh (3 personen)',
          '4.100 kWh (3 personen)',
        ),
        new Selectable(
          '4.600 kWh (4 personen)',
          '4.600 kWh (4 personen)',
          '4.600 kWh (4 personen)',
        ),
        new Selectable(
          '5.300 kWh (5 personen)',
          '5.300 kWh (5 personen)',
          '5.300 kWh (5 personen)',
        ),
        new Selectable(
          '5.400 kWh (6 personen)',
          '5.400 kWh (6 personen)',
          '5.400 kWh (6 personen)',
        ),
        new Selectable(
          'Meer dan 5.400 kWh',
          'Meer dan 5.400 kWh',
          'Meer dan 5.400 kWh',
        ),
        new Selectable(
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
          'Ik kan geen schatting maken',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      const service = this.$store.getters['lead/getData']('service')
      const consumerServices = [
        solarPanelsPurchaseSolarCollectorsConsumerNL.id,
        solarPanelsPurchaseConsumerNL.id,
        solarPanelsRentConsumerNL.id,
      ]
      if (consumerServices.includes(service)) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
